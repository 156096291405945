import React from "react"
// import Privacy from "./components/Privacy/Privacy"
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy"




export default function Privacy() {
    return (
        <PrivacyPolicy />
    )
}