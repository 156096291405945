import React from "react"
import "./privacypolicy.scss"

export default function PrivacyPolicy() {
    return (
        <div className="privacy--policy">
            <div className="container">
                <h2>プライバシーポリシー</h2>
                <p>当社は、個人情報保護の重要性を認識し、個人情報保護の方針を定めるとともに、全社一丸となり個人情報の適切な保護に努めます。<br></br>個人情報の収集、利用に関する基本原則、管理方法ならびに実効性を持たせる手段として教育・訓練、監査等について以下のとおり規定し、実行して参ります。</p>
                <h3>個人情報の収集、利用、提供等に関する基本原則</h3>
                <p>個人情報を直接収集する際は、適法かつ公正な手段により、本人の同意を得た上で行います。<br></br>
収集にあたっては、利用目的を明確にし、その目的のために必要な範囲内にとどめます。<br></br>
個人の利益を侵害する可能性が高い機微な情報は、本人の明確な同意がある場合または法令等の裏付けがある場合以外には収集しません。<br></br>
<br></br>
当社が個人情報の処理を伴う業務を外部から受託する場合や外部へ委託する場合は、個人情報に関する秘密の保持、再委託に関する事項、事故時の責任分担、<br></br>
契約終了時の個人情報の返却および消去等について定め、それに従います。<br></br>
個人情報は、本人の同意を得た範囲内で利用、提供します。</p>
                <h3>個人情報の管理について</h3>
                <p>
当社が直接収集または外部から業務を受託する際に入手した個人情報は、正確な状態に保ち、不正アクセス、紛失・破壊・改ざんおよび漏洩等を防止するための措置を講じます。<br></br>
個人情報の処理を伴う業務を外部から受託する場合は、委託者が個人情報を入手した際、本人の同意を得た上で、適法かつ公正な手段によって収集したものであることを確認します。<br></br>
</p>
                <h3>法令及びその他の規範について</h3>
                <p>当社は、個人情報の保護に関係する日本の法令及びその他の規範を遵守し、本方針の継続的改善に努めます。</p>
                <h3>本人からのお問い合わせ</h3>
                <p>本人からの個人情報の取扱いに関するお問い合わせには、妥当な範囲において、すみやかな対応に努めます。</p>
                </div>
            </div>
    )
}